<script lang="ts">
  import type { ContentBlock } from '$lib/contentful/models/content-block';
  import Text from '$lib/components/text/text.svelte';
  import Button from '$lib/components/button/button.svelte';
  import Grid from '$lib/layout/grid/grid.svelte';
  import Box from '$lib/layout/grid/box.svelte';
  import CodeBlock from '$lib/components/code-block/code-block.svelte';
  import MiniCard from '../mini-card/mini-card.svelte';
  import RichText from '../rich-text/rich-text.svelte';
  import ContentfulImage from '../contentful-image/contentful-image.svelte';
  import Testimonial from '../testimonial/testimonial.svelte';
  import Card from '../card/card.svelte';
  import LogoGroup from '../logo-group/logo-group.svelte';
  import GithubButton from '../github-button/github-button.svelte';
  import { page } from '$app/stores';
  import MarketoForm from '../marketo-form/marketo-form.svelte';

  type $$Props = {
    content: ContentBlock | undefined;
    id?: string;
  };

  export let content: ContentBlock | undefined;
  export let id: string | undefined = undefined;
</script>

{#if content}
  {@const {
    eyebrow,
    heading,
    bodyMarkdown,
    codeBlock,
    image,
    callsToAction,
    entityId,
    background,
    imageBorder,
    reverse,
    miniCards,
    testimonial,
    card,
    anchor,
    logos,
    ossCallToAction,
    marketoForm,
  } = content}

  {@const hasSlotContent =
    marketoForm ||
    codeBlock ||
    image ||
    miniCards ||
    testimonial ||
    card ||
    $$props.slot}

  {@const hasTextContent =
    !!eyebrow ||
    !!heading ||
    !!$$slots.heading ||
    !!bodyMarkdown ||
    callsToAction.length > 0}

  <Grid as="section" layout="columns" id={anchor ?? id} {background}>
    {#if hasTextContent}
      <Box
        as="div"
        class="flex flex-col {marketoForm ? 'justify-start' : 'justify-center'}"
        span={hasSlotContent ? 4 : 10}
        offset={reverse ? 9 : 3}
      >
        <div class="content">
          {#if eyebrow}
            <Text
              as="p"
              variant="eyebrow-16"
              {background}
              {entityId}
              fieldId="eyebrow"
            >
              <slot name="eyebrow">{eyebrow}</slot>
            </Text>
          {/if}

          {#if heading || $$slots.heading}
            <Text
              as="h3"
              variant="display-68"
              {background}
              {entityId}
              fieldId="heading"
            >
              <slot name="heading">{heading}</slot>
            </Text>
          {/if}

          <RichText
            {background}
            fieldId="body"
            {entityId}
            text={bodyMarkdown}
          />
          {#if callsToAction.length > 0 || ossCallToAction}
            <div
              class="flex flex-col md:flex-row gap-2 md:gap-8 mb-4 md:mt-4 md:mb-0"
            >
              {#if ossCallToAction}
                {@const { githubStats } = $page.data}
                <img
                  class="h-16 w-16"
                  src="/images/open-source-initiative.svg"
                  alt="Open Source Initiative Logo"
                />
                <GithubButton stars={githubStats.stars} />
              {/if}
              {#if callsToAction.length > 0}
                {#each callsToAction as { text, ...rest }}
                  <Button {...rest}>{text}</Button>
                {/each}
              {/if}
            </div>
          {/if}
        </div>
      </Box>
    {/if}
    <Box
      as="div"
      offset={hasTextContent ? (reverse ? 3 : 8) : 2}
      span={hasTextContent ? 5 : 12}
    >
      <slot>
        {#if marketoForm}
          <MarketoForm content={marketoForm} />
        {:else if codeBlock}
          <CodeBlock content={codeBlock} />
        {:else if miniCards}
          <div class="grid max-lg:grid-cols-1 lg:grid-cols-2 gap-4">
            {#each miniCards as miniCard}
              <MiniCard content={miniCard} />
            {/each}
          </div>
        {:else if image}
          <div class="flex h-full items-center justify-center">
            <ContentfulImage
              src={image.url}
              alt={image.title}
              data-contentful-entry-id={entityId}
              data-contentful-field-id="image"
              class={imageBorder
                ? 'rounded-2xl border-gradient-ultraviolet border-4 border-transparent'
                : ''}
            />
          </div>
        {:else if testimonial}
          <Testimonial content={testimonial} />
        {:else if card}
          <Card content={card} />
        {/if}
      </slot>
    </Box>
    {#if logos}
      <LogoGroup wrapInGrid={false} content={logos} />
    {/if}
  </Grid>
{/if}

<style lang="postcss">
  .content {
    @apply flex flex-col gap-6;
  }
</style>
